export default function LogoCloud() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
                    Trusted by AkwaIbomites all over the world
                </h2>
                <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    <img
                        className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                        src="https://vip.akwaibomstate.gov.ng/wp-content/uploads/2023/07/AKS-Emblem-G-1.png"
                        alt="Transistor"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                        src="https://akisanhouston.org/wp-content/uploads/2021/07/akisan-web-logo.png"
                        alt="Transistor"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                        src="https://www.akisan.org/wp-content/uploads/2023/10/akisan-new-logo.jpg"
                        alt="Transistor"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                        src="https://www.nigerianorganizations.com/cdn-cgi/imagedelivery/n12z2FIWKA5iB6vfvG4ygw/Akwa-Ibom-State-Association-of-Nigeria-USA-Inc-New-York-63a080ea51f15/public"
                        alt="Transistor"
                        width={158}
                        height={48}
                    />
                    {/* <img
                        className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                        src="https://vip.akwaibomstate.gov.ng/wp-content/uploads/2023/07/AKS-Emblem-G-1.png"
                        alt="Transistor"
                        width={158}
                        height={48}
                    /> */}
                </div>
            </div>
        </div>
    )
}
