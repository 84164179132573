export default function JoinUsForm() {
    return (
        <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
            <svg
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                        width={200}
                        height={200}
                        x="50%"
                        y={-64}
                        patternUnits="userSpaceOnUse"
                    >
                        <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="50%" y={-64} className="overflow-visible fill-gray-50">
                    <path
                        d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
                        strokeWidth={0}
                    />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
            </svg>
            <div className="mx-auto max-w-xl lg:max-w-4xl">
                <h2 className="text-4xl font-bold tracking-tight text-gray-900">Become Part of Akwaibom Diaspora</h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                    Join our community by filling in your details below.
                </p>
                <div className="mt-16 flex flex-col gap-16 sm:gap-y-20 lg:flex-row">
                    <form action="#" method="POST" className="lg:flex-auto">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                    First name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Last name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Email
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Phone Number (Optional)
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="phone"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Country of Residence
                                </label>
                                <div className="mt-2.5">
                                    <select
                                        name="country"
                                        id="country"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value=""></option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="USA">USA</option>
                                        <option value="UK">United Kingdom</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Australia">Australia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="UAE">United Arab Emirates</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Spain">Spain</option>
                                        <option value="France">France</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="China">China</option>
                                        <option value="India">India</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Austria">Austria</option>
                                        <option value="New Zealand">New Zealand</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="lga" className="block text-sm font-semibold leading-6 text-gray-900">
                                    LGA of Origin
                                </label>
                                <div className="mt-2.5">
                                    <select
                                        name="lga"
                                        id="lga"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value=""></option>
                                        <option value="Abak">Abak</option>
                                        <option value="Eastern Obolo">Eastern Obolo</option>
                                        <option value="Eket">Eket</option>
                                        <option value="Esit Eket">Esit Eket</option>
                                        <option value="Essien Udim">Essien Udim</option>
                                        <option value="Etim Ekpo">Etim Ekpo</option>
                                        <option value="Etinan">Etinan</option>
                                        <option value="Ibeno">Ibeno</option>
                                        <option value="Ibesikpo Asutan">Ibesikpo Asutan</option>
                                        <option value="Ibiono-Ibom">Ibiono-Ibom</option>
                                        <option value="Ika">Ika</option>
                                        <option value="Ikono">Ikono</option>
                                        <option value="Ikot Abasi">Ikot Abasi</option>
                                        <option value="Ikot Ekpene">Ikot Ekpene</option>
                                        <option value="Ini">Ini</option>
                                        <option value="Itu">Itu</option>
                                        <option value="Mbo">Mbo</option>
                                        <option value="Mkpat-Enin">Mkpat-Enin</option>
                                        <option value="Nsit-Atai">Nsit-Atai</option>
                                        <option value="Nsit-Ibom">Nsit-Ibom</option>
                                        <option value="Nsit-Ubium">Nsit-Ubium</option>
                                        <option value="Obot Akara">Obot Akara</option>
                                        <option value="Okobo">Okobo</option>
                                        <option value="Onna">Onna</option>
                                        <option value="Oron">Oron</option>
                                        <option value="Oruk Anam">Oruk Anam</option>
                                        <option value="Udung-Uko">Udung-Uko</option>
                                        <option value="Ukanafun">Ukanafun</option>
                                        <option value="Uruan">Uruan</option>
                                        <option value="Urue-Offong/Oruko">Urue-Offong/Oruko</option>
                                        <option value="Uyo">Uyo</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="profession" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Profession
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="profession"
                                        id="profession"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Message (Optional)
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={4}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={''}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-10">
                            <button
                                type="submit"
                                className="block w-full rounded-md bg-[#ff7d1a] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#00563b] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Let’s talk
                            </button>
                        </div>
                        <p className="mt-4 text-sm leading-6 text-gray-500">
                            By submitting this form, I acknowledge the Akwaibom Diaspora{' '}
                            <a href="#" className="font-semibold text-indigo-600">
                                privacy&nbsp;policy
                            </a>
                            .
                        </p>
                    </form>
                    <div className="lg:mt-6 lg:w-80 lg:flex-none">
                        <img className="h-16 w-auto" src="https://vip.akwaibomstate.gov.ng/wp-content/uploads/2023/07/AKS-Emblem-G-1.png" alt="" />
                        <figure className="mt-10">
                            <blockquote className="text-lg font-semibold leading-8 text-gray-900">
                                <p>
                                    “Being part of the Akwaibom Diaspora has been a transformative experience. The sense of community and shared culture is unparalleled.”
                                </p>
                            </blockquote>
                            <figcaption className="mt-10 flex gap-x-6">
                                <img
                                    src="Etim.jpeg"
                                    alt=""
                                    className="h-16 w-12 flex-none rounded-full bg-gray-50"
                                />
                                <div>
                                    <div className="text-base font-semibold text-gray-900">Uduak Etim</div>
                                    <div className="text-sm leading-6 text-gray-600">Member of Akwaibom Diaspora</div>
                                </div>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}
