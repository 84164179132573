import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Dashboard from './Components/Dashboard';
import HomePage from './Pages/HomePage';


function App() {
  return (
    <HomePage />
  );
}

export default App;
