import React from 'react';
import HeroSection from "../Components/HeroSection";
import FeatureSection from '../Components/FeatureSection';
import JoinUsForm from '../Components/JoinUsForm';
import LogoCloud from '../Components/LogoCloud';


export default function HomePage() {
    return (
        <>
            <HeroSection />
            <FeatureSection />
            <LogoCloud />
            <JoinUsForm />
        </>
    );
}
