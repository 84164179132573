import { GlobeAltIcon, ChatBubbleBottomCenterIcon, UserIcon } from '@heroicons/react/24/outline'

const features = [
    {
        name: 'Global Community',
        description:
            'Reconnect with fellow Akwa Ibomites across continents. Share memories, collaborate on projects, and revive the spirit of our homeland.',
        href: '#',
        icon: GlobeAltIcon,
    },
    {
        name: 'Diaspora Stories',
        description:
            'Discover inspiring stories of Akwa Ibomites making waves abroad. Contribute your own tale and inspire the next generation.',
        href: '#',
        icon: ChatBubbleBottomCenterIcon,
    },
    {
        name: 'Family Ties',
        description:
            'Trace your lineage, meet distant relatives, and preserve our rich ancestry for future generations. Know your roots, no matter where you are.',
        href: '#',
        icon: UserIcon,
    },
]

export default function FeatureSection() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Reconnecting Akwa Ibom Diaspora
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Dive deep into our shared heritage, bond with fellow Akwa Ibomites, and celebrate our traditions, wherever you are.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-[#ff7d1a]">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                    <p className="flex-auto">{feature.description}</p>
                                    <p className="mt-6">
                                        <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-600">
                                            Learn more <span aria-hidden="true">→</span>
                                        </a>
                                    </p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
